@use '@angular/material' as mat;
@import '@angular/material/theming';
@import '/src/styles/palette';
@include mat.core();

//$mottu-primary:  mat-palette($mat-teal);
$mottu-primary:  mat.define-palette($md-mottu);
$mottu-accent:  mat.define-palette(mat.$teal-palette, A100, A100, A100);

$mottu-warn: mat.define-palette(mat.$red-palette);

$mottu-theme: mat.define-light-theme( $mottu-primary, $mottu-accent,  $mottu-warn);

@include mat.all-component-themes($mottu-theme);


html,body{
    height: 100%;
    margin: 0;
}