.mat-raised-button:not([class*="mat-primary"]) {
    box-shadow: 0px 3px 1px -2px #f3f3f3, 0px 2px 2px 0px #cecece, 0px 1px 5px 0px #cecece !important;
}
.mat-select-panel:not([class*="mat-primary"]) {
    box-shadow: 0px 2px 4px -1px #f3f3f3, 0px 4px 5px 0px #cecece, 0px 1px 10px 0px #cecece !important;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #f7f7f7 !important;
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: #cecece !important;
}
.mat-step-icon:not(.mat-step-icon-selected) {
    background-color: #cccccc !important;
}
.mat-button-disabled {
    background-color: #f7f7f7 !important;
    color: #a7a7a7 !important;
}
