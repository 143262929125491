@use '@angular/material' as mat;
@import '~@mottu-ops/global-components/vars';
@import '~@mottu-ops/global-components/mottu-theme';

$mottu-typography: mat.define-typography-config(
	$display-4: mat.define-typography-level(112px, 112px, 300, $font-family: $font-extra-bold, $letter-spacing: -0.05em),
	$display-3: mat.define-typography-level(56px, 56px, 400, $font-family: $font-extra-bold, $letter-spacing: -0.02em),
	$display-2: mat.define-typography-level(45px, 48px, 400, $font-family: $font-extra-bold, $letter-spacing: -0.005em),
	$display-1: mat.define-typography-level(34px, 40px, 400, $font-family: $font-extra-bold),
	$headline: mat.define-typography-level(
		$font-family: $font-extra-bold,
		$font-weight: 800,
		$font-size: 2em,
		$line-height: 1,
		$letter-spacing: normal,
	),
	$title: mat.define-typography-level(
		$font-family: $font-semi-bold,
		$font-weight: 800,
		$font-size: 1.5em,
		$line-height: 1,
		$letter-spacing: normal,
	),
	$subheading-2: mat.define-typography-level(
		$font-family: $font-medium,
		$font-weight: inherit,
		$font-size: 1.17em,
		$line-height: 1,
		$letter-spacing: normal,
	),
	$subheading-1: mat.define-typography-level(
		$font-family: $font-medium,
		$font-weight: 500,
		$font-size: 1em,
		$line-height: 1,
		$letter-spacing: normal,
	),
	$body-1: mat.define-typography-level(
		$font-family: $font-regular,
		$font-weight: 400,
		$font-size: 1rem,
		$line-height: 20px,
		$letter-spacing: normal,
	),
	$body-2: mat.define-typography-level(
		$font-family: $font-semi-bold,
		$font-weight: 600,
		$font-size: 1rem,
		$line-height: 20px,
		$letter-spacing: normal,
	),
	$caption: mat.define-typography-level(
		$font-family: $font-regular,
		$font-weight: 400,
		$font-size: 1rem,
		$line-height: 1,
		$letter-spacing: normal,
	),
	$button: mat.define-typography-level(
		$font-family: $font-bold,
		$font-weight: 800,
		$font-size: 1rem,
		$line-height: 1,
		$letter-spacing: 2px,
	),
	$input: mat.define-typography-level(
		$font-family: $font-medium,
		$font-weight: 400,
		$font-size: 1rem,
		$line-height: 1,
		$letter-spacing: normal,
	),
);

$theme: mat.define-light-theme(
		(
			color: (
				primary: mat.define-palette($mat-primary, main, lighter, darker),
				accent: mat.define-palette($mat-accent, main, lighter, darker),
				warn: mat.define-palette($mat-warn, main, lighter, darker),
			),
		)
);

@include mat.core($mottu-typography);
@include mat.all-component-themes($theme);

h1 {
	text-transform: uppercase;
}

h3 {
	font-weight: 400;
	strong, b {
		font-weight: 700;
	}
}

button, .mat-button-base {
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 700;
}
