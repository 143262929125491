@use '@worktile/gantt/styles/index.scss';

@import 'material-icons/iconfont/material-icons.scss';
@import 'material-symbols';

@import "~@mottu-ops/global-components/fonts-mottu";
@import "~@mottu-ops/global-components/vars";


.mat-slide-toggle-bar {
  background-color: grey !important;
}

.mat-select-panel {
  max-height: 265px;
}

.mat-select-arrow-wrapper {
  margin-top: 10px;
}

.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none !important;
}

b {
  font-family: $font-bold;
}

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: black !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #00b131;
}

.mat-radio-outer-circle {
  .mat-radio-checked & {
    border-color: #00b131 !important;
  }
}

.mat-radio-inner-circle {
  background-color: #00b131 !important;
}

.mat-radio-ripple .mat-ripple-element {
  background-color: rgba(#00b131, 0.26) !important;
}

.mat-tab-body-wrapper,
mat-tab-body,
.mat-tab-body-content {
  height: 100% !important;
}

.mat-tab-body-wrapper {
  height: 100% !important;
}

mat-tab-group {
  height: 100% !important;
}

.mat-dialog-actions {
  float: right;
}

.md-drppicker {
  left: 0 !important;
}

.md-drppicker td.active,
.md-drppicker td.active:hover,
.md-drppicker .btn {
  background-color: #00b131 !important;
}

.mat-checkbox-inner-container {
  margin-left: 15px !important;
}

.lg-input {
  width: 400px;
}

.md-input {
  width: 200px;
}

.sm-input {
  width: 120px;
}

.xs-input {
  width: 80px;
}

mat-form-field {
  margin-right: 10px;
}

.mat-form-field-outline {
  background-color: white;
}

.loader-ctn {
  position: absolute;
  width: 100%;
  background-color: #ffffff52;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  display: flex !important;
}

* {
  font-family: $font-regular;
  font-weight: normal;
}

::ng-deep .snackbar-error {
  background-color: rgb(255, 0, 64) !important;
}

.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  width: 257px !important;
  height: 257px !important;
}

.route-line {
  stroke-dasharray: 10;
  animation: dash 5s linear infinite reverse;
}

#description::-webkit-scrollbar {
  width: 3px;
}

#description::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#description::-webkit-scrollbar-thumb {
  background: #bbb;
}

#description::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fagbot-backdrop {
  background-color: rgba(43, 43, 43, 0.90);
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 10;
}

.flex-11 {
  flex: 11;
}

.flex-12 {
  flex: 12;
}